import axios from 'axios';

const apiKey = 'sk-proj-XpkRpDd2vKWZkagGFHouT3BlbkFJzVFP8NFMOi9WRgQvayDf';
const endpoint = 'https://api.openai.com/v1/chat/completions';

export async function fetchWordDetails(word) {
  const prompt = `Define this " ${word}"  and make sure to explain the type of word with the correct abbreviation like n. or v. or adj. or if its a slang or suffix or etc. (make sure its the correct type of word) (make sure if its an ending with ing its probably a verb) and put this in a bracket always behind the word provide clear example and defintiion . make sure to be concise but broad. make sure readbility by splitting into good paragraphs, readability is very important. give only one example with the format example: and always in a seperate paragraph, make sure to not repeat example twice, and never acknowledge the prompt just answer it well, think carefully, then in another paragraph if you think it needs more context can add in another paragraph, use best judgement and explain as clearly and concise as possible, focus on using simple words and simple example, answer should be 11/10 in clear language and readability`;
  const data = {
    model: 'gpt-3.5-turbo',
    messages: [{ role: 'user', content: prompt }],
    max_tokens: 250,
    temperature: 0.5,
    n: 1
  };
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${apiKey}`
    }
  };

  const response = await axios.post(endpoint, data, config);
  const formattedResponse = response.data.choices[0].message.content.replace(/\n/g, '<br>');
  return formattedResponse; // Return the promise containing the formatted response
}

export async function translateWordToChinese(word) {
  const prompt = `Translate the word '${word}' to Chinese.`;
  const data = {
    model: 'gpt-3.5-turbo',
    messages: [{ role: 'user', content: prompt }],
    max_tokens: 60,  // Assuming less tokens needed for a simple translation
    temperature: 0.5,
    n: 1
  };
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${apiKey}`
    }
  };

  const response = await axios.post(endpoint, data, config);
  return response.data.choices[0].message.content.trim(); // Return the translated text
}

export async function WritePractice(word) {
  const prompt = `Pretend you are a smart professor (dont acknowledge this during the response, just reply) and rate this writing " '${word}' " from 1 to 10 and explain the score in the scoring,. Next , explain how it can improved . give useful feedback on how it can be improved. score shouldnt be low just because its longer also. be fair. dont acknowledge the prompt when replying but make sure to think carefully about my instructions, just answer. depending on the writing given, use best judment how long your reply should be, if short give short concise response if longer give more detailed repsonse, split in good paragraphs for readability. focus on clear concise language in response. and try not exceed 250 token`;
  const data = {
    model: 'gpt-3.5-turbo',
    messages: [{ role: 'user', content: prompt }],
    max_tokens: 300,  // 
    temperature: 0.5,
    n: 1
  };
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${apiKey}`
    }
  };

  const response = await axios.post(endpoint, data, config);
  const formattedResponse = response.data.choices[0].message.content.replace(/\n/g, '<br>');
  return formattedResponse; // Return the promise containing the formatted response
}