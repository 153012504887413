import React, { useState } from 'react';
import axios from 'axios';
import './Motivation.css';  // Importing the CSS for styling

const API_KEY = 'sk-proj-XpkRpDd2vKWZkagGFHouT3BlbkFJzVFP8NFMOi9WRgQvayDf';
const ENDPOINT = 'https://api.openai.com/v1/chat/completions';

async function fetchMotivation(feeling) {
    const prompt = `Provide a motivational quote based on this feeling: ${feeling}`;
    const data = {
        model: 'gpt-3.5-turbo',
        messages: [{ role: 'user', content: prompt }],
        max_tokens: 60,
        temperature: 0.5
    };
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${API_KEY}`
        }
    };

    try {
        const response = await axios.post(ENDPOINT, data, config);
        return response.data.choices[0].message.content.trim();
    } catch (error) {
        console.error('Error fetching motivational quote:', error);
        return 'Failed to fetch quote.';
    }
}

function Motivation() {
    const [input, setInput] = useState('');
    const [quote, setQuote] = useState('');

    const handleInputChange = (event) => {
        setInput(event.target.value);
    };

    const handleFetchQuote = async () => {
        const fetchedQuote = await fetchMotivation(input);
        setQuote(fetchedQuote);
        setInput(''); // Optionally clear input after fetching
    };

    return (
        <div className="motivation-container">
            <input
                type="text"
                value={input}
                onChange={handleInputChange}
                placeholder="How are you feeling today?"
                className="motivation-input"
            />
            <button onClick={handleFetchQuote} className="motivation-button">Get Motivation</button>
            <p className="motivation-quote">{quote}</p>
        </div>
    );
}

export default Motivation;
