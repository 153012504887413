import React from 'react';
import './Quiz.css'; // Ensure the CSS is correctly imported

const Quiz = () => {
  return (
    <div className="quiz-container">
      <h1 className="quiz-title">Quiz</h1>
      <p className="coming-soon-message">Quiz functionality coming soon!</p>
    </div>
  );
};

export default Quiz;
