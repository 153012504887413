import React, { useState } from 'react';
import axios from 'axios';  // Correctly importing axios
import './Poetry.css';  // Importing the CSS for styling

const API_KEY = 'sk-proj-XpkRpDd2vKWZkagGFHouT3BlbkFJzVFP8NFMOi9WRgQvayDf';  // Your actual API key
const ENDPOINT = 'https://api.openai.com/v1/chat/completions';

function Poetry() {
    const [theme, setTheme] = useState('');
    const [tone, setTone] = useState('');
    const [lines, setLines] = useState(4);
    const [poetry, setPoetry] = useState('');
    const [showForm, setShowForm] = useState(true);

    const canGenerate = theme && tone && lines;

    const fetchPoetry = async () => {
        if (!canGenerate) {
            alert('Please make sure to select a theme, tone, and the number of lines before generating a poem.');
            return;
        }

        const prompt = `Compose a poem of ${lines} lines on the theme of "${theme}" with a "${tone}" tone. Also write a title for the poem but the title at the top, just write the name, you must not specify that it's a title, menntioning the name is enough. title is not counted towards lines.`;
        const data = {
            model: 'gpt-3.5-turbo',
            messages: [{ role: 'user', content: prompt }],
            max_tokens: 400
        };
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${API_KEY}`
            }
        };

        try {
            const response = await axios.post(ENDPOINT, data, config);
            setPoetry(response.data.choices[0].message.content.trim().replace(/\n/g, '<br>'));
            setShowForm(false); // Hide the form on successful fetch
        } catch (error) {
            console.error('Error fetching poetry:', error);
            alert('Failed to fetch poetry. Please try again.');
        }
    };

    const handleReset = () => {
        setTheme('');
        setTone('');
        setLines(4);
        setPoetry('');
        setShowForm(true);  // Show the form again for new input
    };

    return (
        <div className="poetry-container">
            {showForm && (
                <>
                    <div className="form-group">
                        <label htmlFor="theme">Theme:</label>
                        <select
                            id="theme"
                            value={theme}
                            onChange={e => setTheme(e.target.value)}
                        >
                            <option value="">Select Theme</option>
                            <option value="nature">Nature</option>
                            <option value="love">Love</option>
                            <option value="time">Time</option>
                            <option value="adventure">Adventure</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="tone">Tone:</label>
                        <select
                            id="tone"
                            value={tone}
                            onChange={e => setTone(e.target.value)}
                        >
                            <option value="">Select Tone</option>
                            <option value="joyful">Joyful</option>
                            <option value="sad">Sad</option>
                            <option value="reflective">Reflective</option>
                            <option value="humorous">Humorous</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="lines">Number of Lines:</label>
                        <select
                            id="lines"
                            value={lines}
                            onChange={e => setLines(parseInt(e.target.value, 10))}
                        >
                            <option value="4">4 Lines</option>
                            <option value="8">8 Lines</option>
                            <option value="12">12 Lines</option>
                        </select>
                    </div>
                    <button onClick={fetchPoetry} className="generate-button" disabled={!canGenerate}>Generate Poem</button>
                </>
            )}
            {poetry && !showForm && (
                <div>
                    <div className="poetry-output" dangerouslySetInnerHTML={{ __html: poetry }}></div>
                    <button onClick={fetchPoetry} className="generate-button">Generate Again</button>
                    <button onClick={handleReset} className="reset-button">Reset</button>
                </div>
            )}
        </div>
    );
}

export default Poetry;
