import React, { useState } from 'react';
import './WordOfTheDay.css';
import WordList from './WordList';

function WordOfTheDay() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [language, setLanguage] = useState('');

  const handleDateChange = (days) => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + days);
    if (newDate > new Date()) {
      // Reset to today if they try to go past today
      setCurrentDate(new Date());
    } else {
      setCurrentDate(newDate);
    }
  };

  const getFormattedDateKey = (date) => {
    return date.toLocaleDateString('en-CA', { timeZone: 'Europe/Berlin' });
  };

  const getFormattedDisplayDate = (date) => {
    return date.toLocaleDateString('en-US', {
      timeZone: 'Europe/Berlin',
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const currentDateKey = getFormattedDateKey(currentDate);
  const displayDate = getFormattedDisplayDate(currentDate);

  const wordData = WordList[currentDateKey] || {
    word: "No word available",
    definition: "No definition available",
    translations: { cn: { word: "", definition: "" }, de: { word: "", definition: "" } }
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const speakWord = (textToSpeak) => {
    if ('speechSynthesis' in window) {
      const msg = new SpeechSynthesisUtterance();
      msg.text = textToSpeak;
      window.speechSynthesis.speak(msg);
    } else {
      alert('Your browser does not support speech synthesis.');
    }
  };

  const isForwardDisabled = () => {
    return new Date(currentDate).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0);
  };

  return (
    <div className="container">
      <div className="date-display">
        <button onClick={() => handleDateChange(-1)} className="date-nav" title="Go to previous day">◀</button>
        {displayDate}
        <button onClick={() => handleDateChange(1)} className="date-nav" disabled={isForwardDisabled()} title={isForwardDisabled() ? "No future dates available" : "Go to next day"}>
          ▶
        </button>
      </div>
      <h1 className="heading">
        <span className="text-center">Word of the Day</span>
        <button onClick={() => speakWord(wordData.word)} className="speaker-button">
          🔊
        </button>
      </h1>

      <div className="controls">
        <select className="dropdown" onChange={handleLanguageChange} value={language}>
          <option value="" hidden>{language === '' ? 'Select Language' : (language === 'cn' ? '中文' : 'Deutsch')}</option>
          {language !== 'cn' && <option value="cn">中文 (Chinese)</option>}
          {language !== 'de' && <option value="de">Deutsch (German)</option>}
          {language && <option value="">Reset to English</option>}
        </select>
      </div>

      <div className="word">
        {wordData.word}
        {language && <p>{wordData.translations[language].word}</p>}
      </div>

      <div className="definition">
        Definition:
        <p>{wordData.definition}</p>
        {language && <p>{wordData.translations[language].definition}</p>}
      </div>
    </div>
  );
}

export default WordOfTheDay;
