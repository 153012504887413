import React from 'react';
import { NavLink, Routes, Route } from 'react-router-dom';
import './Chill.css'; // Importing Chill.css for styling
import Motivation from './Motivation'; // Importing the Motivation component
import Poetry from './Poetry'; // Importing the Poetry component
import Joke from './Joke'; // Importing the Joke component

function ChillTab() {
    return (
        <div className="chill-container"> {/* This applies styles defined for .chill-container in Chill.css */}
            <div className="chill-tabs"> {/* This applies styles defined for .chill-tabs in Chill.css */}
                <NavLink 
                    to="/chill/motivation" 
                    className={({ isActive }) => isActive ? 'chill-tab active' : 'chill-tab'} // Applies .chill-tab and .active styles
                >
                    Motivation
                </NavLink>
                
                <NavLink 
                    to="/chill/poetry" 
                    className={({ isActive }) => isActive ? 'chill-tab active' : 'chill-tab'}
                >
                    Poetry
                </NavLink>
                <NavLink 
                    to="/chill/jokes" 
                    className={({ isActive }) => isActive ? 'chill-tab active' : 'chill-tab'}
                >
                    Jokes
                </NavLink>
            </div>
            <div className="tab-content"> {/* This applies styles defined for .tab-content in Chill.css */}
                <Routes>
                    <Route path="motivation" element={<Motivation />} />
                    <Route path="poetry" element={<Poetry />} />
                    <Route path="jokes" element={<Joke />} /> {/* Integrating the Joke component */}
                </Routes>
            </div>
        </div>
    );
}

export default ChillTab;
