import React, { useState } from 'react';
import axios from 'axios';
import './Joke.css';  // Make sure the CSS file is correctly referenced

const API_KEY = 'sk-proj-XpkRpDd2vKWZkagGFHouT3BlbkFJzVFP8NFMOi9WRgQvayDf';  // Your actual API key
const ENDPOINT = 'https://api.openai.com/v1/chat/completions';

function Joke() {
    const [theme, setTheme] = useState('');
    const [type, setType] = useState('');
    const [joke, setJoke] = useState('');
    const [showForm, setShowForm] = useState(true);

    const canGenerate = theme && type;

    const fetchJoke = async () => {
        if (!canGenerate) {
            alert('Please make sure to select a theme and a type of joke before generating.');
            return;
        }

        const prompt = `Create a joke about ${theme} that is a ${type}.`;
        const data = {
            model: 'gpt-3.5-turbo',
            messages: [{ role: 'user', content: prompt }],
            max_tokens: 200
        };
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${API_KEY}`
            }
        };

        try {
            const response = await axios.post(ENDPOINT, data, config);
            setJoke(response.data.choices[0].message.content.trim().replace(/\n/g, '<br>'));
            setShowForm(false); // Hide the form on successful fetch
        } catch (error) {
            console.error('Error fetching joke:', error);
            alert('Failed to fetch joke. Please try again.');
        }
    };

    const handleReset = () => {
        setTheme('');
        setType('');
        setJoke('');
        setShowForm(true);  // Show the form again for new input
    };

    return (
        <div className="joke-container">
            {showForm && (
                <>
                    <div className="form-group">
                        <label htmlFor="theme">Theme:</label>
                        <select
                            id="theme"
                            value={theme}
                            onChange={e => setTheme(e.target.value)}
                        >
                            <option value="">Select Theme</option>
                            <option value="animals">Animals</option>
                            <option value="technology">Technology</option>
                            <option value="sports">Sports</option>
                            <option value="politics">Politics</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="type">Type of Joke:</label>
                        <select
                            id="type"
                            value={type}
                            onChange={e => setType(e.target.value)}
                        >
                            <option value="">Select Type of Joke</option>
                            <option value="pun">Pun</option>
                            <option value="one-liner">One-liner</option>
                            <option value="knock-knock">Knock-knock</option>
                            <option value="dad-joke">Dad Joke</option>
                        </select>
                    </div>
                    <button onClick={fetchJoke} className="generate-button" disabled={!canGenerate}>Generate Joke</button>
                </>
            )}
            {joke && !showForm && (
                <div>
                    <div className="joke-output" dangerouslySetInnerHTML={{ __html: joke }}></div>
                    <button onClick={fetchJoke} className="generate-button">Generate Again</button>
                    <button onClick={handleReset} className="reset-button">Reset</button>
                </div>
            )}
        </div>
    );
}

export default Joke;
