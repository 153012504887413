const WordList = {
  "2024-04-30": {
    word: "Harmony",
    definition: "The combination of simultaneously sounded musical notes to produce a pleasing effect.",
    translations: {
      cn: {
        word: "和谐",
        definition: "同时响起的音乐音符的组合，以产生令人愉悦的效果。"
      },
      de: {
        word: "Harmonie",
        definition: "Die Kombination von gleichzeitig erklingenden musikalischen Noten, um einen angenehmen Effekt zu erzeugen."
      }
    }
  },
  "2024-05-01": {
    word: "Cacophony",
    definition: "A harsh, discordant mixture of sounds.",
    translations: {
      cn: {
        word: "刺耳的声音",
        definition: "声音的刺耳、不和谐的混合。"
      },
      de: {
        word: "Kakophonie",
        definition: "Eine harte, disharmonische Mischung von Klängen."
      }
    }
  },
  "2024-05-02": {
    word: "Ebullient",
    definition: "Cheerful and full of energy.",
    translations: {
      cn: {
        word: "兴高采烈",
        definition: "快乐和充满活力。"
      },
      de: {
        word: "Überschwänglich",
        definition: "Fröhlich und voller Energie."
      }
    }
  },
  "2024-05-03": {
    word: "Laconic",
    definition: "Using very few words.",
    translations: {
      cn: {
        word: "简洁",
        definition: "使用很少的词汇。"
      },
      de: {
        word: "Lakonisch",
        definition: "Mit sehr wenigen Worten."
      }
    }
  },
  "2024-05-04": {
    word: "Recalcitrant",
    definition: "Having a stubborn uncooperative attitude towards authority or discipline.",
    translations: {
      cn: {
        word: "顽抗",
        definition: "对权威或纪律持有顽固不合作的态度。"
      },
      de: {
        word: "Widerspenstig",
        definition: "Eine hartnäckig unkooperative Haltung gegenüber Autorität oder Disziplin haben."
      }
    }
  },
  "2024-05-05": {
    word: "Inexorable",
    definition: "Impossible to stop or prevent.",
    translations: {
      cn: {
        word: "无法阻挡",
        definition: "不可能停止或防止。"
      },
      de: {
        word: "Unaufhaltsam",
        definition: "Unmöglich zu stoppen oder zu verhindern."
      }
    }
  },
  "2024-05-06": {
    word: "Obfuscate",
    definition: "Render obscure, unclear, or unintelligible.",
    translations: {
      cn: {
        word: "混淆",
        definition: "使模糊、不清楚或难以理解。"
      },
      de: {
        word: "Verdunkeln",
        definition: "Unklar oder unverständlich machen."
      }
    }
  },
  "2024-05-07": {
    word: "Proclivity",
    definition: "A tendency to choose or do something regularly; an inclination or predisposition towards a particular thing.",
    translations: {
      cn: {
        word: "倾向",
        definition: "定期选择或做某事的倾向；对某事的倾斜或偏好。"
      },
      de: {
        word: "Neigung",
        definition: "Eine Tendenz, regelmäßig etwas zu wählen oder zu tun; eine Neigung oder Prädisposition zu einer bestimmten Sache."
      }
    }
  },
  "2024-05-08": {
    word: "Chicanery",
    definition: "The use of trickery to achieve a political, financial, or legal purpose.",
    translations: {
      cn: {
        word: "诡计",
        definition: "使用诡计达到政治、金融或法律的目的。"
      },
      de: {
        word: "Schikane",
        definition: "Die Verwendung von Trickerei, um einen politischen, finanziellen oder rechtlichen Zweck zu erreichen."
      }
    }
  },
  "2024-05-09": {
    word: "Demagogue",
    definition: "A political leader who seeks support by appealing to the desires and prejudices of ordinary people rather than by using rational argument.",
    translations: {
      cn: {
        word: "煽动者",
        definition: "一个政治领导人，通过迎合普通人的愿望和偏见而非使用理性论证来寻求支持。"
      },
      de: {
        word: "Demagoge",
        definition: "Ein politischer Führer, der Unterstützung sucht, indem er sich an die Wünsche und Vorurteile der gewöhnlichen Menschen wendet, statt rationale Argumente zu verwenden."
      }
    }
  },
  "2024-05-10": {
    word: "Insidious",
    definition: "Proceeding in a gradual, subtle way, but with harmful effects.",
    translations: {
      cn: {
        word: "潜伏的",
        definition: "以渐进、微妙的方式进行，但具有有害的影响。"
      },
      de: {
        word: "Heimtückisch",
        definition: "Auf schleichende, subtile Weise fortschreitend, aber mit schädlichen Auswirkungen."
      }
    }
  },
  "2024-05-11": {
    word: "Obdurate",
    definition: "Stubbornly refusing to change one's opinion or course of action.",
    translations: {
      cn: {
        word: "固执",
        definition: "固执地拒绝改变意见或行动方向。"
      },
      de: {
        word: "Hartnäckig",
        definition: "Stur weigert sich, seine Meinung oder sein Handeln zu ändern."
      }
    }
  },
  "2024-05-12": {
    word: "Sagacity",
    definition: "The quality of being sagacious.",
    translations: {
      cn: {
        word: "睿智",
        definition: "具有睿智的品质。"
      },
      de: {
        word: "Weisheit",
        definition: "Die Qualität, weise zu sein."
      }
    }
  },
  "2024-05-13": {
    word: "Veracity",
    definition: "Conformity to facts; accuracy.",
    translations: {
      cn: {
        word: "真实性",
        definition: "符合事实；准确性。"
      },
      de: {
        word: "Wahrhaftigkeit",
        definition: "Übereinstimmung mit den Fakten; Genauigkeit."
      }
    }
  },
  "2024-05-14": {
    "word": "Acumen",
    "definition": "The ability to make good judgments and quick decisions, typically in a particular domain.",
    "translations": {
      "cn": {
        "word": "敏锐",
        "definition": "在特定领域快速做出良好判断的能力。"
      },
      "de": {
        "word": "Scharfsinn",
        "definition": "Die Fähigkeit, gute Urteile und schnelle Entscheidungen zu treffen, typischerweise in einem bestimmten Bereich."
      }
    }
  },
  "2024-05-15": {
    "word": "Candor",
    "definition": "The quality of being open and honest in expression; frankness.",
    "translations": {
      "cn": {
        "word": "坦率",
        "definition": "表达时开放和诚实的品质；直率。"
      },
      "de": {
        "word": "Offenheit",
        "definition": "Die Qualität, in der Ausdrucksweise offen und ehrlich zu sein; Freimütigkeit."
      }
    }
  },
  
  "2024-05-16": {
    "word": "Tenacity",
    "definition": "The quality or fact of being able to grip something firmly; persistence.",
    "translations": {
      "cn": {
        "word": "顽强",
        "definition": "紧紧抓住某物的能力或事实；持久性。"
      },
      "de": {
        "word": "Zähigkeit",
        "definition": "Die Qualität oder Tatsache, etwas fest greifen zu können; Ausdauer."
      }
    }
  },
  "2024-05-17": {
    "word": "Ineffable",
    "definition": "Too great or extreme to be expressed or described in words.",
    "translations": {
      "cn": {
        "word": "难以形容",
        "definition": "极端地大或极端地难以用言语表达或描述。"
      },
      "de": {
        "word": "Unaussprechlich",
        "definition": "Zu groß oder extrem, um in Worten ausgedrückt oder beschrieben zu werden."
      }
    }
  },
  "2024-05-18": {
    "word": "Pernicious",
    "definition": "Having a harmful effect, especially in a gradual or subtle way.",
    "translations": {
      "cn": {
        "word": "恶性",
        "definition": "尤其是以一种逐渐的或微妙的方式产生有害影响。"
      },
      "de": {
        "word": "Schädlich",
        "definition": "Hat eine schädliche Wirkung, besonders auf eine allmähliche oder subtile Weise."
      }
    }
  },
  "2024-05-19": {
    "word": "Abound",
    "definition": "To be present in large numbers or in great quantity; to be prevalent.",
    "translations": {
      "cn": {
        "word": "大量存在",
        "definition": "以大量存在或大量存在；盛行。"
      },
      "de": {
        "word": "Überfließen",
        "definition": "In großer Anzahl oder in großer Menge vorhanden sein; weit verbreitet sein."
      }
    }
  },
  "2024-05-20": {
    "word": "Amorphous",
    "definition": "Having no definite form; shapeless.",
    "translations": {
      "cn": {
        "word": "无定形的",
        "definition": "没有明确的形状；无定形的。"
      },
      "de": {
        "word": "Amorph",
        "definition": "Keine feste Form haben; formlos."
      }
    }
  },
  "2024-05-21": {
    "word": "Austere",
    "definition": "Stern and cold in appearance or manner.",
    "translations": {
      "cn": {
        "word": "严峻的",
        "definition": "外表或态度严肃而冷漠。"
      },
      "de": {
        "word": "Streng",
        "definition": "Streng und kalt im Aussehen oder Verhalten."
      }
    }
  },
  "2024-05-22": {
    "word": "Belie",
    "definition": "To give a false impression of.",
    "translations": {
      "cn": {
        "word": "掩饰",
        "definition": "给人一种错误的印象。"
      },
      "de": {
        "word": "Widerlegen",
        "definition": "Einen falschen Eindruck vermitteln."
      }
    }
  },
  "2024-05-23": {
    "word": "Capricious",
    "definition": "Governed or characterized by caprice; impulsive, unpredictable.",
    "translations": {
      "cn": {
        "word": "反复无常的",
        "definition": "由反复无常支配或特征；冲动的，不可预测的。"
      },
      "de": {
        "word": "Launisch",
        "definition": "Von Launen regiert oder geprägt; impulsiv, unvorhersehbar."
      }
    }
  },
  "2024-05-24": {
    "word": "Cerebral",
    "definition": "Of or relating to the brain or the intellect.",
    "translations": {
      "cn": {
        "word": "大脑的",
        "definition": "与大脑或智力有关的。"
      },
      "de": {
        "word": "Zerebral",
        "definition": "Das Gehirn oder den Intellekt betreffend."
      }
    }
  },
  "2024-05-25": {
    "word": "Congenial",
    "definition": "Pleasant; especially agreeably suited to one's nature, tastes, or outlook.",
    "translations": {
      "cn": {
        "word": "意气相投的",
        "definition": "令人愉快的；尤其适合某人的性格、品味或观点的。"
      },
      "de": {
        "word": "Sympathisch",
        "definition": "Angenehm; besonders passend zu jemandes Wesen, Geschmack oder Ansicht."
      }
    }
  },
  "2024-05-26": {
    "word": "Conspicuous",
    "definition": "Obvious to the eye or mind.",
    "translations": {
      "cn": {
        "word": "显眼的",
        "definition": "显而易见的。"
      },
      "de": {
        "word": "Auffällig",
        "definition": "Offensichtlich für Auge oder Verstand."
      }
    }
  },
  "2024-05-27": {
    "word": "Cursory",
    "definition": "Rapidly and often superficially performed or produced; hasty.",
    "translations": {
      "cn": {
        "word": "粗略的",
        "definition": "快速且通常表面上完成或产生的；仓促的。"
      },
      "de": {
        "word": "Oberflächlich",
        "definition": "Schnell und oft oberflächlich durchgeführt oder produziert; hastig."
      }
    }
  },
  "2024-05-28": {
    "word": "Daunting",
    "definition": "Tending to overwhelm or intimidate.",
    "translations": {
      "cn": {
        "word": "令人畏惧的",
        "definition": "倾向于压倒或恐吓。"
      },
      "de": {
        "word": "Einschüchternd",
        "definition": "Dazu neigend, zu überwältigen oder einzuschüchtern."
      }
    }
  },
  "2024-05-29": {
    "word": "Didactic",
    "definition": "Designed or intended to teach.",
    "translations": {
      "cn": {
        "word": "教导的",
        "definition": "设计或打算教导的。"
      },
      "de": {
        "word": "Didaktisch",
        "definition": "Zum Lehren konzipiert oder bestimmt."
      }
    }
  },
  "2024-05-30": {
    "word": "Disseminate",
    "definition": "To spread abroad as though sowing seed.",
    "translations": {
      "cn": {
        "word": "传播",
        "definition": "像播种一样传播。"
      },
      "de": {
        "word": "Verbreiten",
        "definition": "Verbreiten, als ob man Samen sät."
      }
    }
  },
  "2024-05-31": {
    "word": "Feasible",
    "definition": "Capable of being done or carried out.",
    "translations": {
      "cn": {
        "word": "可行的",
        "definition": "能够完成或执行的。"
      },
      "de": {
        "word": "Machbar",
        "definition": "Fähig, ausgeführt oder durchgeführt zu werden."
      }
    }
  },
  "2024-06-01": {
    "word": "Flout",
    "definition": "To treat with contemptuous disregard; scorn.",
    "translations": {
      "cn": {
        "word": "蔑视",
        "definition": "以轻蔑的态度对待；嘲笑。"
      },
      "de": {
        "word": "Missachten",
        "definition": "Mit verächtlicher Missachtung behandeln; verachten."
      }
    }
  },
  "2024-06-02": {
    "word": "Homogeneous",
    "definition": "Of the same or a similar kind or nature.",
    "translations": {
      "cn": {
        "word": "同质的",
        "definition": "相同或相似的种类或性质。"
      },
      "de": {
        "word": "Homogen",
        "definition": "Von gleicher oder ähnlicher Art oder Natur."
      }
    }
  },
  "2024-06-03": {
    "word": "Humdrum",
    "definition": "Monotonous, dull.",
    "translations": {
      "cn": {
        "word": "单调的",
        "definition": "单调的，乏味的。"
      },
      "de": {
        "word": "Eintönig",
        "definition": "Monoton, langweilig."
      }
    }
  },
  "2024-06-04": {
    "word": "Insipid",
    "definition": "Lacking in qualities that interest, stimulate, or challenge; dull, flat.",
    "translations": {
      "cn": {
        "word": "无味的",
        "definition": "缺乏有趣、刺激或挑战的品质；乏味的，平淡的。"
      },
      "de": {
        "word": "Geschmacklos",
        "definition": "Fehlende Eigenschaften, die interessieren, stimulieren oder herausfordern; langweilig, flach."
      }
    }
  },
  "2024-06-05": {
    "word": "Loquacious",
    "definition": "Full of excessive talk; wordy.",
    "translations": {
      "cn": {
        "word": "话多的",
        "definition": "充满过多的谈话；啰嗦的。"
      },
      "de": {
        "word": "Geschwätzig",
        "definition": "Voll von übermäßigem Gerede; wortreich."
      }
    }
  },
  "2024-06-06": {
    "word": "Misanthropic",
    "definition": "Of, relating to, or characteristic of a misanthrope.",
    "translations": {
      "cn": {
        "word": "厌世的",
        "definition": "关于或特征厌世者的。"
      },
      "de": {
        "word": "Misanthropisch",
        "definition": "Von, bezogen auf oder charakteristisch für einen Menschenfeind."
      }
    }
  },
  "2024-06-07": {
    "word": "Misnomer",
    "definition": "The misnaming of a person in a legal instrument.",
    "translations": {
      "cn": {
        "word": "用词不当",
        "definition": "在法律文书中对一个人的错误命名。"
      },
      "de": {
        "word": "Fehlbenennung",
        "definition": "Die falsche Benennung einer Person in einem Rechtsdokument."
      }
    }
  },
  "2024-06-08": {
    "word": "Negligent",
    "definition": "Marked by or given to neglect especially habitually or culpably.",
    "translations": {
      "cn": {
        "word": "疏忽的",
        "definition": "尤其是习惯性或有罪地表现出或倾向于忽视的。"
      },
      "de": {
        "word": "Fahrlässig",
        "definition": "Gekennzeichnet durch oder gegeben zur Vernachlässigung, besonders gewohnheitsmäßig oder schuldhaft."
      }
    }
  },
  "2024-06-09": {
    "word": "Obsequious",
    "definition": "Marked by or exhibiting a fawning attentiveness.",
    "translations": {
      "cn": {
        "word": "谄媚的",
        "definition": "表现出或展示出谄媚的关注。"
      },
      "de": {
        "word": "Unterwürfig",
        "definition": "Gekennzeichnet durch oder ausstellend eine schmeichlerische Aufmerksamkeit."
      }
    }
  },
  "2024-06-10": {
    "word": "Placate",
    "definition": "To soothe or mollify especially by concessions; appease.",
    "translations": {
      "cn": {
        "word": "安抚",
        "definition": "尤其是通过让步来安抚或缓和。"
      },
      "de": {
        "word": "Beschwichtigen",
        "definition": "Beruhigen oder besänftigen, besonders durch Zugeständnisse; besänftigen."
      }
    }
  },
  "2024-06-11": {
    "word": "Puerile",
    "definition": "Juvenile.",
    "translations": {
      "cn": {
        "word": "幼稚的",
        "definition": "幼稚的。"
      },
      "de": {
        "word": "Kindisch",
        "definition": "Jugendlich."
      }
    }
  },
  "2024-06-12": {
    "word": "Quixotic",
    "definition": "Foolishly impractical especially in the pursuit of ideals; especially marked by rash lofty romantic ideas or extravagantly chivalrous action.",
    "translations": {
      "cn": {
        "word": "堂吉诃德式的",
        "definition": "愚蠢地不切实际，特别是在追求理想时；尤其是由冲动的崇高浪漫主义思想或极端骑士行为标志的。"
      },
      "de": {
        "word": "Unrealistisch",
        "definition": "Törichterweise unpraktisch, besonders bei der Verfolgung von Idealen; besonders gekennzeichnet durch überstürzte, hochromantische Ideen oder extravagante ritterliche Handlungen."
      }
    }
  },
  "2024-06-13": {
    "word": "Spendthrift",
    "definition": "A person who spends improvidently or wastefully.",
    "translations": {
      "cn": {
        "word": "挥霍者",
        "definition": "一个花钱不节制或浪费的人。"
      },
      "de": {
        "word": "Verschwender",
        "definition": "Eine Person, die unvorsichtig oder verschwenderisch ausgibt."
      }
    }
  },
  "2024-06-14": {
    "word": "Taciturn",
    "definition": "Temperamentally disinclined to talk.",
    "translations": {
      "cn": {
        "word": "沉默寡言的",
        "definition": "性格上不爱说话的。"
      },
      "de": {
        "word": "Schweigsam",
        "definition": "Temperamentvoll abgeneigt zu reden."
      }
    }
  },
  "2024-06-15": {
    "word": "Wary",
    "definition": "Marked by keen caution, cunning, and watchfulness especially in detecting and escaping danger.",
    "translations": {
      "cn": {
        "word": "警惕的",
        "definition": "尤其是在发现和逃避危险时表现出敏锐的警觉、狡猾和警惕。"
      },
      "de": {
        "word": "Vorsichtig",
        "definition": "Gekennzeichnet durch scharfe Vorsicht, Schlauheit und Wachsamkeit, besonders bei der Erkennung und Flucht vor Gefahren."
      }
    }
  },
  "2024-06-16": {
    word: "Ameliorate",
    definition: "To make better or more tolerable.",
    translations: {
      cn: {
        word: "改善",
        definition: "使更好或更能忍受。"
      },
      de: {
        word: "Verbessern",
        definition: "Besser oder erträglicher machen."
      }
    }
  },
  "2024-06-17": {
    word: "Benevolent",
    definition: "Well meaning and kindly.",
    translations: {
      cn: {
        word: "仁慈的",
        definition: "好意且和善的。"
      },
      de: {
        word: "Wohlwollend",
        definition: "Wohlmeinend und freundlich."
      }
    }
  },
  "2024-06-18": {
    word: "Cajole",
    definition: "To persuade with flattery or gentle urging especially in the face of reluctance.",
    translations: {
      cn: {
        word: "哄骗",
        definition: "尤其在不情愿的情况下，通过恭维或温和的劝说来说服。"
      },
      de: {
        word: "Überreden",
        definition: "Mit Schmeichelei oder sanfter Drängelei überzeugen, besonders bei Widerwillen."
      }
    }
  },
  "2024-06-19": {
    word: "Dauntless",
    definition: "Showing fearlessness and determination.",
    translations: {
      cn: {
        word: "无畏的",
        definition: "表现出无畏和决心。"
      },
      de: {
        word: "Unerschrocken",
        definition: "Furchtlosigkeit und Entschlossenheit zeigen."
      }
    }
  },
  "2024-06-20": {
    word: "Ebullience",
    definition: "The quality of being cheerful and full of energy; exuberance.",
    translations: {
      cn: {
        word: "热情洋溢",
        definition: "快乐和充满活力的品质；热情。"
      },
      de: {
        word: "Überschwang",
        definition: "Die Qualität, fröhlich und voller Energie zu sein; Überschwänglichkeit."
      }
    }
  },
  "2024-06-21": {
    word: "Furtive",
    definition: "Attempting to avoid notice or attention, typically because of guilt or a belief that discovery would lead to trouble; secretive.",
    translations: {
      cn: {
        word: "偷偷摸摸的",
        definition: "试图避免注意或关注，通常是因为内疚或认为被发现会导致麻烦；秘密的。"
      },
      de: {
        word: "Heimlich",
        definition: "Versuchend, Aufmerksamkeit oder Aufmerksamkeit zu vermeiden, typischerweise aufgrund von Schuld oder der Überzeugung, dass die Entdeckung zu Problemen führen würde; geheimnisvoll."
      }
    }
  },
  "2024-06-22": {
    word: "Garrulous",
    definition: "Excessively talkative, especially on trivial matters.",
    translations: {
      cn: {
        word: "喋喋不休的",
        definition: "过分健谈，尤其是在琐事上。"
      },
      de: {
        word: "Geschwätzig",
        definition: "Übermäßig gesprächig, besonders bei belanglosen Dingen."
      }
    }
  },
  "2024-06-23": {
    word: "Hapless",
    definition: "Unfortunate.",
    translations: {
      cn: {
        word: "倒霉的",
        definition: "不幸的。"
      },
      de: {
        word: "Unglücklich",
        definition: "Unglücklich."
      }
    }
  },
  "2024-06-24": {
    word: "Iconoclast",
    definition: "A person who attacks or criticizes cherished beliefs or institutions.",
    translations: {
      cn: {
        word: "反传统者",
        definition: "攻击或批评受人珍视的信仰或机构的人。"
      },
      de: {
        word: "Ikonoklast",
        definition: "Eine Person, die geschätzte Überzeugungen oder Institutionen angreift oder kritisiert."
      }
    }
  },
  "2024-06-25": {
    word: "Juxtapose",
    definition: "To place side by side for contrast.",
    translations: {
      cn: {
        word: "并置",
        definition: "并排放置以对比。"
      },
      de: {
        word: "Nebeneinanderstellen",
        definition: "Nebeneinander stellen, um zu kontrastieren."
      }
    }
  },
  "2024-06-26": {
    word: "Kinetic",
    definition: "Relating to or resulting from motion.",
    translations: {
      cn: {
        word: "运动的",
        definition: "与运动有关或由运动产生的。"
      },
      de: {
        word: "Kinetisch",
        definition: "Bezogen auf oder resultierend aus Bewegung."
      }
    }
  },
  "2024-06-27": {
    word: "Lambaste",
    definition: "To criticize (someone or something) harshly.",
    translations: {
      cn: {
        word: "严厉批评",
        definition: "严厉批评（某人或某事）。"
      },
      de: {
        word: "Scharf kritisieren",
        definition: "Jemanden oder etwas scharf kritisieren."
      }
    }
  },
  "2024-06-28": {
    word: "Magnanimous",
    definition: "Very generous or forgiving, especially toward a rival or someone less powerful than oneself.",
    translations: {
      cn: {
        word: "宽宏大量的",
        definition: "非常慷慨或宽容，尤其是对竞争对手或权力较自己小的人。"
      },
      de: {
        word: "Großzügig",
        definition: "Sehr großzügig oder verzeihend, besonders gegenüber einem Rivalen oder jemandem, der weniger mächtig ist als man selbst."
      }
    }
  },
  "2024-06-29": {
    word: "Nefarious",
    definition: "Wicked or criminal.",
    translations: {
      cn: {
        word: "邪恶的",
        definition: "邪恶或犯罪的。"
      },
      de: {
        word: "Bösartig",
        definition: "Böse oder kriminell."
      }
    }
  },
  "2024-06-30": {
    word: "Oblique",
    definition: "Neither parallel nor at a right angle to a specified or implied line; slanting.",
    translations: {
      cn: {
        word: "斜的",
        definition: "既不平行也不与指定或隐含的线成直角；倾斜的。"
      },
      de: {
        word: "Schräg",
        definition: "Weder parallel noch im rechten Winkel zu einer angegebenen oder implizierten Linie; schräg."
      }
    }
  },
  "2024-07-01": {
    word: "Palpable",
    definition: "So intense as to seem almost tangible.",
    translations: {
      cn: {
        word: "可感知的",
        definition: "如此强烈以至于似乎几乎可以触摸。"
      },
      de: {
        word: "Spürbar",
        definition: "So intensiv, dass es fast greifbar erscheint."
      }
    }
  },
  "2024-07-02": {
    word: "Quintessential",
    definition: "Representing the most perfect or typical example of a quality or class.",
    translations: {
      cn: {
        word: "典型的",
        definition: "代表某一品质或类别的最完美或最典型的例子。"
      },
      de: {
        word: "Inbegriff",
        definition: "Den vollkommensten oder typischsten Vertreter einer Qualität oder Klasse darstellend."
      }
    }
  },
  "2024-07-03": {
    word: "Recalcitrant",
    definition: "Having an obstinately uncooperative attitude toward authority or discipline.",
    translations: {
      cn: {
        word: "顽抗的",
        definition: "对权威或纪律有顽固的不合作态度。"
      },
      de: {
        word: "Widerspenstig",
        definition: "Eine hartnäckig unkooperative Haltung gegenüber Autorität oder Disziplin haben."
      }
    }
  },
  "2024-07-04": {
    word: "Salient",
    definition: "Most noticeable or important.",
    translations: {
      cn: {
        word: "显著的",
        definition: "最显著或最重要的。"
      },
      de: {
        word: "Auffällig",
        definition: "Am auffälligsten oder wichtigsten."
      }
    }
  },
  "2024-07-05": {
    word: "Trepidation",
    definition: "A feeling of fear or agitation about something that may happen.",
    translations: {
      cn: {
        word: "不安",
        definition: "对可能发生的事情感到害怕或不安。"
      },
      de: {
        word: "Angst",
        definition: "Ein Gefühl der Angst oder Unruhe über etwas, das passieren könnte."
      }
    }
  },
  "2024-07-06": {
    word: "Ubiquitous",
    definition: "Present, appearing, or found everywhere.",
    translations: {
      cn: {
        word: "无处不在的",
        definition: "无处不在的。"
      },
      de: {
        word: "Allgegenwärtig",
        definition: "Überall vorhanden, auftretend oder zu finden."
      }
    }
  },
  "2024-07-07": {
    word: "Verbose",
    definition: "Using or expressed in more words than are needed.",
    translations: {
      cn: {
        word: "冗长的",
        definition: "使用或表达的词语比需要的更多。"
      },
      de: {
        word: "Weitschweifig",
        definition: "Mit mehr Worten als nötig ausgedrückt."
      }
    }
  },
  "2024-07-08": {
    word: "Wistful",
    definition: "Having or showing a feeling of vague or regretful longing.",
    translations: {
      cn: {
        word: "惆怅的",
        definition: "有或表现出模糊或遗憾的渴望。"
      },
      de: {
        word: "Wehmütig",
        definition: "Ein Gefühl der vagen oder bedauernden Sehnsucht haben oder zeigen."
      }
    }
  },
  "2024-07-09": {
    word: "Xenophobia",
    definition: "Dislike of or prejudice against people from other countries.",
    translations: {
      cn: {
        word: "排外",
        definition: "对来自其他国家的人的不喜欢或偏见。"
      },
      de: {
        word: "Fremdenfeindlichkeit",
        definition: "Abneigung oder Vorurteile gegen Menschen aus anderen Ländern."
      }
    }
  },
  "2024-07-10": {
    word: "Yield",
    definition: "To produce or provide (a natural, agricultural, or industrial product).",
    translations: {
      cn: {
        word: "产量",
        definition: "生产或提供（自然的、农业的或工业的产品）。"
      },
      de: {
        word: "Ertrag",
        definition: "Ein natürliches, landwirtschaftliches oder industrielles Produkt produzieren oder liefern."
      }
    }
  },
  "2024-07-11": {
    word: "Zealous",
    definition: "Having or showing zeal.",
    translations: {
      cn: {
        word: "热心的",
        definition: "具有或表现出热情。"
      },
      de: {
        word: "Eifrig",
        definition: "Eifer haben oder zeigen."
      }
    }
  },
  "2024-07-12": {
    word: "Abstruse",
    definition: "Difficult to understand; obscure.",
    translations: {
      cn: {
        word: "深奥的",
        definition: "难以理解的；模糊的。"
      },
      de: {
        word: "Abstrus",
        definition: "Schwierig zu verstehen; dunkel."
      }
    }
  },
  "2024-07-13": {
    word: "Banal",
    definition: "So lacking in originality as to be obvious and boring.",
    translations: {
      cn: {
        word: "陈腐的",
        definition: "如此缺乏原创性，以至于显得明显和无聊。"
      },
      de: {
        word: "Banal",
        definition: "So sehr an Originalität mangeln, dass es offensichtlich und langweilig ist."
      }
    }
  },
  "2024-07-14": {
    word: "Cacophonous",
    definition: "Involving or producing a harsh, discordant mixture of sounds.",
    translations: {
      cn: {
        word: "刺耳的",
        definition: "涉及或产生刺耳、不和谐的声音混合。"
      },
      de: {
        word: "Kakophonisch",
        definition: "Eine harte, disharmonische Mischung von Klängen beinhalten oder erzeugen."
      }
    }
  },
  "2024-07-15": {
    word: "Debilitate",
    definition: "To make (someone) weak and infirm.",
    translations: {
      cn: {
        word: "使虚弱",
        definition: "使（某人）虚弱。"
      },
      de: {
        word: "Schwächen",
        definition: "Jemanden schwach und gebrechlich machen."
      }
    }
  },
  "2024-07-16": {
    word: "Ebullient",
    definition: "Cheerful and full of energy.",
    translations: {
      cn: {
        word: "兴高采烈的",
        definition: "快乐和充满活力的。"
      },
      de: {
        word: "Überschwänglich",
        definition: "Fröhlich und voller Energie."
      }
    }
  },
  "2024-07-17": {
    word: "Facetious",
    definition: "Treating serious issues with deliberately inappropriate humor.",
    translations: {
      cn: {
        word: "轻率的",
        definition: "用故意不恰当的幽默对待严肃的问题。"
      },
      de: {
        word: "Schalkhaft",
        definition: "Ernsthafte Themen mit absichtlich unangemessenem Humor behandeln."
      }
    }
  },
  "2024-07-18": {
    word: "Garrulous",
    definition: "Excessively talkative, especially on trivial matters.",
    translations: {
      cn: {
        word: "喋喋不休的",
        definition: "过分健谈的，尤其在琐事上。"
      },
      de: {
        word: "Geschwätzig",
        definition: "Übermäßig gesprächig, besonders bei belanglosen Dingen."
      }
    }
  },
  "2024-07-19": {
    word: "Hapless",
    definition: "Unfortunate.",
    translations: {
      cn: {
        word: "不幸的",
        definition: "不幸的。"
      },
      de: {
        word: "Unglücklich",
        definition: "Unglücklich."
      }
    }
  },
  "2024-07-20": {
    word: "Iconoclastic",
    definition: "Characterized by attack on cherished beliefs or institutions.",
    translations: {
      cn: {
        word: "反传统的",
        definition: "以攻击受人珍视的信仰或机构为特征的。"
      },
      de: {
        word: "Ikonoklastisch",
        definition: "Gekennzeichnet durch Angriffe auf geschätzte Überzeugungen oder Institutionen."
      }
    }
  },
  "2024-07-21": {
    word: "Jocular",
    definition: "Fond of or characterized by joking; humorous or playful.",
    translations: {
      cn: {
        word: "爱开玩笑的",
        definition: "喜欢或以开玩笑为特征的；幽默的或爱玩的。"
      },
      de: {
        word: "Scherzhaft",
        definition: "Liebend oder gekennzeichnet durch Scherze; humorvoll oder verspielt."
      }
    }
  },
  "2024-07-22": {
    word: "Keen",
    definition: "Having or showing eagerness or enthusiasm.",
    translations: {
      cn: {
        word: "热衷的",
        definition: "有或表现出热情或热情的。"
      },
      de: {
        word: "Eifrig",
        definition: "Eifer haben oder zeigen."
      }
    }
  },
  "2024-07-23": {
    word: "Lachrymose",
    definition: "Tearful or given to weeping.",
    translations: {
      cn: {
        word: "爱哭的",
        definition: "爱哭的或喜欢哭泣的。"
      },
      de: {
        word: "Tränenreich",
        definition: "Tränenreich oder zum Weinen neigend."
      }
    }
  },
  "2024-07-24": {
    word: "Mendacious",
    definition: "Not telling the truth; lying.",
    translations: {
      cn: {
        word: "虚假的",
        definition: "不说实话；撒谎的。"
      },
      de: {
        word: "Verlogen",
        definition: "Die Wahrheit nicht sagen; lügen."
      }
    }
  },
  "2024-07-25": {
    word: "Spurious",
    definition: "Not being what it purports to be; false or fake.",
    translations: {
      cn: {
        word: "虚假的",
        definition: "不是真实的；假或伪造的。"
      },
      de: {
        word: "Falsch",
        definition: "Nicht das sein, was es vorgibt zu sein; falsch oder gefälscht."
      }
    }
  },
  "2024-07-26": {
    word: "Terse",
    definition: "Sparing in the use of words; abrupt.",
    translations: {
      cn: {
        word: "简洁的",
        definition: "用词简练；简短的。"
      },
      de: {
        word: "Kurz und bündig",
        definition: "Sparsam im Gebrauch von Worten; abrupt."
      }
    }
  },
  "2024-07-27": {
    word: "Urbane",
    definition: "Suave, courteous, and refined in manner.",
    translations: {
      cn: {
        word: "温文尔雅的",
        definition: "举止温文尔雅，彬彬有礼。"
      },
      de: {
        word: "Weltgewandt",
        definition: "Charmant, höflich und kultiviert im Verhalten."
      }
    }
  },
  "2024-07-28": {
    word: "Vociferous",
    definition: "Vehement or clamorous.",
    translations: {
      cn: {
        word: "喧嚷的",
        definition: "激烈或吵闹的。"
      },
      de: {
        word: "Lautstark",
        definition: "Heftig oder lärmend."
      }
    }
  },
  "2024-07-29": {
    word: "Wane",
    definition: "To decrease in size, extent, or degree.",
    translations: {
      cn: {
        word: "减少",
        definition: "减少大小、范围或程度。"
      },
      de: {
        word: "Abnehmen",
        definition: "An Größe, Ausmaß oder Grad abnehmen."
      }
    }
  },
  "2024-07-30": {
    word: "Xenial",
    definition: "Hospitable, especially to visiting strangers or foreigners.",
    translations: {
      cn: {
        word: "好客的",
        definition: "热情好客的，特别是对待来访的陌生人或外国人。"
      },
      de: {
        word: "Gastfreundlich",
        definition: "Gastfreundlich, besonders gegenüber Besuchern oder Fremden."
      }
    }
  },
  "2024-07-31": {
    word: "Yielding",
    definition: "Giving way under pressure; not hard or rigid.",
    translations: {
      cn: {
        word: "柔顺的",
        definition: "在压力下让步；不硬或不坚固。"
      },
      de: {
        word: "Nachgiebig",
        definition: "Unter Druck nachgeben; nicht hart oder starr."
      }
    }
  },
  "2024-08-01": {
    word: "Zephyr",
    definition: "A gentle, mild breeze.",
    translations: {
      cn: {
        word: "和风",
        definition: "温和的微风。"
      },
      de: {
        word: "Zephyr",
        definition: "Eine sanfte, milde Brise."
      }
    }
  },
  "2024-08-02": {
    word: "Alacrity",
    definition: "Brisk and cheerful readiness.",
    translations: {
      cn: {
        word: "敏捷",
        definition: "敏捷而愉快的准备。"
      },
      de: {
        word: "Bereitwilligkeit",
        definition: "Schnelle und fröhliche Bereitschaft."
      }
    }
  },
  "2024-08-03": {
    word: "Benevolent",
    definition: "Well meaning and kindly.",
    translations: {
      cn: {
        word: "仁慈的",
        definition: "好意且和善的。"
      },
      de: {
        word: "Wohlwollend",
        definition: "Wohlmeinend und freundlich."
      }
    }
  },
  "2024-08-04": {
    word: "Candid",
    definition: "Truthful and straightforward; frank.",
    translations: {
      cn: {
        word: "坦率的",
        definition: "诚实而直率的；坦诚的。"
      },
      de: {
        word: "Aufrichtig",
        definition: "Wahrhaftig und geradeheraus; offen."
      }
    }
  },
  "2024-08-05": {
    word: "Debunk",
    definition: "To expose the falseness or hollowness of (a myth, idea, or belief).",
    translations: {
      cn: {
        word: "揭穿",
        definition: "揭露（神话、观念或信仰）的虚假或空洞。"
      },
      de: {
        word: "Entlarven",
        definition: "Die Falschheit oder Hohlheit von (einem Mythos, einer Idee oder einem Glauben) aufdecken."
      }
    }
  },
  "2024-08-06": {
    word: "Eclectic",
    definition: "Deriving ideas, style, or taste from a broad and diverse range of sources.",
    translations: {
      cn: {
        word: "兼收并蓄的",
        definition: "从广泛而多样的来源获取思想、风格或品味的。"
      },
      de: {
        word: "Vielseitig",
        definition: "Ideen, Stil oder Geschmack aus einer breiten und vielfältigen Auswahl von Quellen beziehen."
      }
    }
  },
  "2024-08-07": {
    word: "Fastidious",
    definition: "Very attentive to and concerned about accuracy and detail.",
    translations: {
      cn: {
        word: "挑剔的",
        definition: "非常注意和关注准确性和细节的。"
      },
      de: {
        word: "Pingelig",
        definition: "Sehr aufmerksam und besorgt über Genauigkeit und Details."
      }
    }
  },
  "2024-08-08": {
    word: "Galvanize",
    definition: "To shock or excite (someone), typically into taking action.",
    translations: {
      cn: {
        word: "激励",
        definition: "震惊或激励（某人），通常使其采取行动。"
      },
      de: {
        word: "Aufrütteln",
        definition: "Jemanden schockieren oder aufregen, typischerweise um ihn zum Handeln zu bewegen."
      }
    }
  },
  "2024-08-09": {
    word: "Heinous",
    definition: "Utterly odious or wicked.",
    translations: {
      cn: {
        word: "极恶的",
        definition: "极其可憎或邪恶的。"
      },
      de: {
        word: "Abscheulich",
        definition: "Völlig abscheulich oder böse."
      }
    }
  },
  "2024-08-10": {
    word: "Irascible",
    definition: "Having or showing a tendency to be easily angered.",
    translations: {
      cn: {
        word: "易怒的",
        definition: "有或表现出易怒的倾向。"
      },
      de: {
        word: "Jähzornig",
        definition: "Die Tendenz haben oder zeigen, leicht wütend zu werden."
      }
    }
  },
  "2024-08-11": {
    word: "Juxtaposition",
    definition: "The fact of two things being seen or placed close together with contrasting effect.",
    translations: {
      cn: {
        word: "并列",
        definition: "两件事物并列或紧靠在一起的事实，产生对比效果。"
      },
      de: {
        word: "Nebeneinanderstellung",
        definition: "Die Tatsache, dass zwei Dinge nahe beieinander gesehen oder platziert werden, mit kontrastierender Wirkung."
      }
    }
  },
  "2024-08-12": {
    word: "Kaleidoscopic",
    definition: "Having complex patterns of colors; multicolored.",
    translations: {
      cn: {
        word: "万花筒般的",
        definition: "具有复杂的颜色图案；多彩的。"
      },
      de: {
        word: "Kaleidoskopisch",
        definition: "Komplexe Farbmustern habend; vielfarbig."
      }
    }
  },
  "2024-08-13": {
    word: "Luminous",
    definition: "Emitting or reflecting light, glowing; illuminating.",
    translations: {
      cn: {
        word: "发光的",
        definition: "发出或反射光，发光；照亮的。"
      },
      de: {
        word: "Leuchtend",
        definition: "Licht emittierend oder reflektierend, glühend; erhellend."
      }
    }
  },
  "2024-08-14": {
    word: "Mirth",
    definition: "Amusement, especially as expressed in laughter.",
    translations: {
      cn: {
        word: "欢笑",
        definition: "娱乐，尤其是以笑声表达的。"
      },
      de: {
        word: "Fröhlichkeit",
        definition: "Belustigung, besonders ausgedrückt im Lachen."
      }
    }
  },
  "2024-08-15": {
    word: "Nebulous",
    definition: "In the form of a cloud or haze; hazy.",
    translations: {
      cn: {
        word: "模糊的",
        definition: "以云或雾的形式；朦胧的。"
      },
      de: {
        word: "Nebulös",
        definition: "In Form einer Wolke oder eines Dunstes; dunstig."
      }
    }
  },
  "2024-08-16": {
    word: "Oblique",
    definition: "Not explicit or done in a direct way.",
    translations: {
      cn: {
        word: "间接的",
        definition: "不明确或不直接进行的。"
      },
      de: {
        word: "Indirekt",
        definition: "Nicht explizit oder direkt durchgeführt."
      }
    }
  },
  "2024-08-17": {
    word: "Perfunctory",
    definition: "Carried out with a minimum of effort or reflection.",
    translations: {
      cn: {
        word: "敷衍的",
        definition: "以最少的努力或反思进行的。"
      },
      de: {
        word: "Oberflächlich",
        definition: "Mit minimalem Aufwand oder Reflexion durchgeführt."
      }
    }
  },
  "2024-08-18": {
    word: "Quintessential",
    definition: "Representing the most perfect or typical example of a quality or class.",
    translations: {
      cn: {
        word: "典型的",
        definition: "代表某一品质或类别的最完美或最典型的例子。"
      },
      de: {
        word: "Inbegriff",
        definition: "Den vollkommensten oder typischsten Vertreter einer Qualität oder Klasse darstellend."
      }
    }
  },
  "2024-08-19": {
    word: "Recalcitrant",
    definition: "Having an obstinately uncooperative attitude toward authority or discipline.",
    translations: {
      cn: {
        word: "顽抗的",
        definition: "对权威或纪律有顽固的不合作态度。"
      },
      de: {
        word: "Widerspenstig",
        definition: "Eine hartnäckig unkooperative Haltung gegenüber Autorität oder Disziplin haben."
      }
    }
  },
  "2024-08-20": {
    word: "Sardonic",
    definition: "Grimly mocking or cynical.",
    translations: {
      cn: {
        word: "讽刺的",
        definition: "冷嘲热讽的或愤世嫉俗的。"
      },
      de: {
        word: "Sardonisch",
        definition: "Grimmig spöttisch oder zynisch."
      }
    }
  },
  "2024-08-21": {
    word: "Tenacious",
    definition: "Tending to keep a firm hold of something; clinging or adhering closely.",
    translations: {
      cn: {
        word: "顽强的",
        definition: "倾向于紧紧抓住某物；紧贴或紧紧依附。"
      },
      de: {
        word: "Zäh",
        definition: "Dazu neigen, fest an etwas festzuhalten; festhalten oder eng haften."
      }
    }
  },
  "2024-08-22": {
    word: "Ubiquitous",
    definition: "Present, appearing, or found everywhere.",
    translations: {
      cn: {
        word: "无处不在的",
        definition: "无处不在的。"
      },
      de: {
        word: "Allgegenwärtig",
        definition: "Überall vorhanden, auftretend oder zu finden."
      }
    }
  },
  "2024-08-23": {
    word: "Voracious",
    definition: "Wanting or devouring great quantities of food.",
    translations: {
      cn: {
        word: "贪婪的",
        definition: "想要或吞食大量食物的。"
      },
      de: {
        word: "Gefräßig",
        definition: "Große Mengen an Nahrung wollen oder verschlingen."
      }
    }
  },
  "2024-08-24": {
    word: "Wry",
    definition: "Using or expressing dry, especially mocking, humor.",
    translations: {
      cn: {
        word: "挖苦的",
        definition: "使用或表达干涩的，尤其是嘲讽的幽默。"
      },
      de: {
        word: "Ironisch",
        definition: "Trockenen, besonders spöttischen Humor verwenden oder ausdrücken."
      }
    }
  },
  "2024-08-25": {
    word: "Zealous",
    definition: "Having or showing zeal.",
    translations: {
      cn: {
        word: "热心的",
        definition: "具有或表现出热情。"
      },
      de: {
        word: "Eifrig",
        definition: "Eifer haben oder zeigen."
      }
    }
  },
  "2024-08-26": {
    word: "Arcane",
    definition: "Understood by few; mysterious or secret.",
    translations: {
      cn: {
        word: "神秘的",
        definition: "只有少数人理解的；神秘的或秘密的。"
      },
      de: {
        word: "Geheimnisvoll",
        definition: "Von wenigen verstanden; mysteriös oder geheim."
      }
    }
  },
  "2024-08-27": {
    word: "Belligerent",
    definition: "Hostile and aggressive.",
    translations: {
      cn: {
        word: "好战的",
        definition: "敌对和好斗的。"
      },
      de: {
        word: "Kämpferisch",
        definition: "Feindselig und aggressiv."
      }
    }
  },
  "2024-08-28": {
    word: "Cacophony",
    definition: "A harsh, discordant mixture of sounds.",
    translations: {
      cn: {
        word: "刺耳的声音",
        definition: "声音的刺耳、不和谐的混合。"
      },
      de: {
        word: "Kakophonie",
        definition: "Eine harte, disharmonische Mischung von Klängen."
      }
    }
  },
  "2024-08-29": {
    word: "Debilitate",
    definition: "To make (someone) weak and infirm.",
    translations: {
      cn: {
        word: "使虚弱",
        definition: "使（某人）虚弱。"
      },
      de: {
        word: "Schwächen",
        definition: "Jemanden schwach und gebrechlich machen."
      }
    }
  },
  "2024-08-30": {
    word: "Ebullient",
    definition: "Cheerful and full of energy.",
    translations: {
      cn: {
        word: "热情洋溢的",
        definition: "充满活力和快乐的。"
      },
      de: {
        word: "Überschwänglich",
        definition: "Fröhlich und voller Energie."
      }
    }
  },
    

  
};

export default WordList;
