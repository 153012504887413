import React, { useState } from 'react';
import axios from 'axios';
import './WritingPractice.css';  // Ensure proper CSS file reference

const API_KEY = 'sk-proj-XpkRpDd2vKWZkagGFHouT3BlbkFJzVFP8NFMOi9WRgQvayDf';  // Use your actual API key
const ENDPOINT = 'https://api.openai.com/v1/chat/completions';

async function fetchWritingAnalysis(inputText) {
    const prompt = `Pretend you are a smart professor (don't acknowledge this during the response, just reply) and rate this writing "${inputText}" from 1 to 10 and explain the score in the scoring. Next, explain how it can be improved. Give useful feedback on how it can be improved. The score shouldn't be low just because it's longer also. Be fair. Don't acknowledge the prompt when replying but make sure to think carefully about my instructions, just answer. Depending on the writing given, use best judgment on how long your reply should be; if short give short concise response if longer give more detailed response, split in good paragraphs for readability. Focus on clear concise language in response and try not to exceed 250 tokens.`;
    const data = {
        model: 'gpt-3.5-turbo',
        messages: [{ role: 'user', content: prompt }],
        max_tokens: 300,
        temperature: 0.5,
        n: 1
    };
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${API_KEY}`
        }
    };

    try {
        const response = await axios.post(ENDPOINT, data, config);
        return response.data.choices[0].message.content.trim().replace(/\n/g, '<br>');
    } catch (error) {
        console.error('Error fetching response:', error);
        return 'Failed to fetch response. Please try again.';
    }
}

function WritingPractice() {
    const [input, setInput] = useState('');
    const [response, setResponse] = useState('');

    const handleInputChange = (event) => {
        setInput(event.target.value);
    };

    const handleSubmit = async () => {
        if (input.trim() === '') {
            setResponse("Please enter some text to analyze.");
            return;
        }
        const analysisResult = await fetchWritingAnalysis(input);
        setResponse(analysisResult);
        setInput(''); // Optionally clear input after submitting, based on desired UX
    };

    const handleReset = () => {
        setInput('');
        setResponse('');
    };

    return (
        <div className="writing-practice-container">
            <h2 className="writing-practice-heading">Writing Practice Area</h2>
            <p className="writing-practice-description">
                Enter your text in the box below and receive a detailed analysis of your writing, including a rating and suggestions for improvement.
            </p>
            <textarea
                className="textarea-input"
                value={input}
                onChange={handleInputChange}
                placeholder="Enter your text here..."
            />
            <div className="button-group">
                <button className="submit-button" onClick={handleSubmit} disabled={!input.trim()}>Submit</button>
                <button className="resetWritingPractice" onClick={handleReset}>Reset</button>
            </div>
            {response && <div className="response-container" dangerouslySetInnerHTML={{ __html: response }}></div>}
        </div>
    );
}

export default WritingPractice;
