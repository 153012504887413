import React, { useState, useCallback, useRef } from 'react';
import { DndProvider, useDrag, useDrop, DragPreviewImage } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import update from 'immutability-helper';
import './App.css';
import './Game.css';

const ItemTypes = {
  QUOTE: 'quote',
};

const passages = [
  {
    id: 1,
    author: "Charles Dickens",
    source: "A Tale of Two Cities, 1859",
    quotes: [
      { id: 1, text: "It was the best of times," },
      { id: 2, text: "it was the worst of times," },
      { id: 3, text: "it was the age of wisdom," },
      { id: 4, text: "it was the age of foolishness," },
    ],
    correctOrder: [1, 2, 3, 4]
  },
 
{
  id: 2,
  author: "Robert Frost",
  source: "The Road Not Taken, 1916",
  quotes: [
  { id: 1, text: "Two roads diverged in a yellow wood," },
  { id: 2, text: "And sorry I could not travel both" },
  { id: 3, text: "And be one traveler, long I stood" },
  { id: 4, text: "And looked down one as far as I could" }
  ],
  correctOrder: [1, 2, 3, 4]
  },

  
{
id: 3,
author: "William Shakespeare",
source: "Hamlet, 1600",
quotes: [
{ id: 1, text: "To be, or not to be," },
{ id: 2, text: "that is the question:" },
{ id: 3, text: "Whether 'tis nobler in the mind to suffer" },
{ id: 4, text: "The slings and arrows of outrageous fortune," }
],
correctOrder: [1, 2, 3, 4]
},
{
id: 4,
author: "Mary Shelley",
source: "Frankenstein, 1818",
quotes: [
{ id: 1, text: "Beware; for I am fearless," },
{ id: 2, text: "and therefore powerful." },
{ id: 3, text: "I have love in me the likes of which you can scarcely imagine" },
{ id: 4, text: "and rage the likes of which you would not believe." }
],
correctOrder: [1, 2, 3, 4]
},


];

const shuffleQuotes = (quotes) => {
  const shuffled = [...quotes];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

const QuoteItem = ({ id, text, index, moveQuote }) => {
  const ref = useRef(null);
  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.QUOTE,
    item: { id, index },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemTypes.QUOTE,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveQuote(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  drag(drop(ref));

  return (
    <>
      <DragPreviewImage connect={preview} src="/drag.png" />
      <div ref={ref} className={`quote-item ${isDragging ? 'dragging' : ''}`}>
        {text}
      </div>
    </>
  );
};

const Game = () => {
  const randomPassageIndex = Math.floor(Math.random() * passages.length);
  const [passageIndex, setPassageIndex] = useState(randomPassageIndex);
  const [quotes, setQuotes] = useState(shuffleQuotes(passages[passageIndex].quotes));

  const moveQuote = useCallback((dragIndex, hoverIndex) => {
    setQuotes((prevQuotes) => update(prevQuotes, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, prevQuotes[dragIndex]],
      ],
    }));
  }, []);

  const checkOrder = () => {
    const currentOrder = quotes.map((q) => q.id);
    if (JSON.stringify(currentOrder) === JSON.stringify(passages[passageIndex].correctOrder)) {
      alert('Correct! Well done!');
    } else {
      alert('Incorrect arrangement. Try again!');
    }
  };

  const handleChangePassage = (index) => {
    setPassageIndex(index);
    setQuotes(shuffleQuotes(passages[index].quotes));
  };

  return (
    <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
      <div className="game-container">
        <select 
          className="select-passage" 
          value={passageIndex} 
          onChange={(e) => handleChangePassage(parseInt(e.target.value, 10))}
        >
          {passages.map((p, index) => (
            <option key={p.id} value={index}>{p.source}</option>
          ))}
        </select>
        <h2 className="passage-title">{passages[passageIndex].author} - {passages[passageIndex].source}</h2>
        <div className="quotes-container">
          {quotes.map((quote, index) => (
            <QuoteItem key={quote.id} id={quote.id} text={quote.text} index={index} moveQuote={moveQuote} />
          ))}
        </div>
        <button className="check-order-button" onClick={checkOrder}>Check Order</button>
      </div>
    </DndProvider>
  );
};

export default Game;