import React, { useState } from 'react';
import './LearnVocabulary.css'; // Ensure the CSS file is imported
import { fetchWordDetails } from './api';

const LearnVocabulary = () => {
  const [inputWord, setInputWord] = useState(() => sessionStorage.getItem('inputWord') || '');
  const [details, setDetails] = useState(() => {
    const savedDetails = sessionStorage.getItem('details');
    return savedDetails ? { __html: savedDetails } : '';
  });
  const [error, setError] = useState('');

  const handleInputChange = (event) => {
    setInputWord(event.target.value);
    sessionStorage.setItem('inputWord', event.target.value);
  };

  const handleLearnClick = async () => {
    if (!inputWord.trim()) { // Check if the inputWord is empty or just whitespace
      setError('Please type a word or phrase to learn about.');
      setDetails('');
      return; // Prevent the API call
    }

    setError('');
    setDetails('');
    sessionStorage.removeItem('details');

    try {
      const detailsHtml = await fetchWordDetails(inputWord.trim()); // Use trim to ensure no leading/trailing whitespace
      setDetails({ __html: detailsHtml });
      sessionStorage.setItem('details', detailsHtml);
    } catch (err) {
      setError('Failed to fetch the details. Please try again.');
      console.error('API error:', err);
    }
  };

  return (
    <div className="container">
      <h1 className="heading">Learn Vocabulary</h1>
      <p className="description">Explore the meaning and usage of any word or phrase</p>
      <div className="input-area">
        <input
          className="input-field"
          type="text"
          value={inputWord}
          onChange={handleInputChange}
          placeholder="Enter a word or phrase"
        />
        <button className="button" onClick={handleLearnClick}>Learn</button>
      </div>
      {error && <p className="error">{error}</p>}
      {details.__html && <div className="details" dangerouslySetInnerHTML={details} />}
    </div>
  );
};

export default LearnVocabulary;
