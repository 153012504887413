import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import './App.css';
import WordOfTheDay from './WordOfTheDay';
import LearnVocabulary from './LearnVocabulary';
import WritingPractice from './WritingPractice';
import Quiz from './Quiz';
import Game from './Game';
import Chill from './Chill';  // Import the new Chill component

const App = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);  // State to manage menu visibility

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);  // Function to toggle menu
  };

  // Effect to listen for window resize events
  useEffect(() => {
    const handleResize = () => {
      if (isMenuOpen && window.innerWidth > 768) {  // Assuming 768px as the breakpoint for mobile to desktop transition
        setIsMenuOpen(false);  // Automatically close the menu on window resize if it's open
      }
    };

    // Register the event listener
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMenuOpen]);  // Dependency array ensures the effect runs when isMenuOpen changes

  return (
    <Router>
      <div className="app-container">
        <div className="app-header">
          <NavLink to="/" onClick={() => setIsMenuOpen(false)}>
            <img src="/logo.png" alt="Logo" className="logo" />
          </NavLink>
          <button className="hamburger" onClick={toggleMenu}>☰</button>
          <nav className={`nav-menu ${isMenuOpen ? 'is-open' : ''}`}>
            <NavLink to="/word-of-the-day" className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'} onClick={toggleMenu}>
              Word of the Day
            </NavLink>
            <NavLink to="/learn-vocabulary" className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'} onClick={toggleMenu}>
              Learn Vocabulary
            </NavLink>
            <NavLink to="/writing-practice" className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'} onClick={toggleMenu}>
              Writing Practice
            </NavLink>
            <NavLink to="/quiz" className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'} onClick={toggleMenu}>
              Quiz
            </NavLink>
            <NavLink to="/game" className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'} onClick={toggleMenu}>
              Game
            </NavLink>
            <NavLink to="/chill" className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'} onClick={toggleMenu}>
  Chill
</NavLink>
            <div className={`overlay ${isMenuOpen ? 'is-active' : ''}`} onClick={toggleMenu}></div>
          </nav>
        </div>
        <main className="tab-content">
          <Routes>
            <Route path="/word-of-the-day" element={<WordOfTheDay />} />
            <Route path="/learn-vocabulary" element={<LearnVocabulary />} />
            <Route path="/writing-practice" element={<WritingPractice />} />
            <Route path="/quiz" element={<Quiz />} />
            <Route path="/game" element={<Game />} />
            <Route path="/chill/*" element={<Chill />} />  // New route for Chill component
            <Route path="/" element={<WordOfTheDay />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
};

export default App;
